import axios, { AxiosResponse } from "axios";
import endPoints from "netwrok/endPoints";
import { RootState } from "store/configStore";
import {
  GeneralLoanType,
  LoanReqData,
  VseLoanReqData,
  LoanReqOnlineData,
} from "types/backend.modal";
import {
  setAmountOfLoansMf,
  setAmountOfLoansSme,
  setAmountOfLoansLv,
} from "./contentActions";

export const API_postOnGroundLoanReqMf = (data: LoanReqData) => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
          "Content-Type": "multipart/form-data",
        },
      };
      let form_data = new FormData();
      form_data.append("full_name", data.full_name);
      form_data.append("phone_number", data.phone_number);
      form_data.append("amount_of_loan", data.amount_of_loan as any);
      form_data.append("product_id", data.amount_of_loan as any);
      form_data.append("branch", String(data.branch));
      form_data.append("government", String(data.government));
      form_data.append("category", data.category);
      form_data.append("activity_type", data.activity_type);
      form_data.append(
        "have_existing_activity",
        String(data.have_existing_activity)
      );
      const res = await axios.post(
        endPoints.application.onGroundLoanReq,
        form_data,
        config
      );
      console.log("API_postOnGroundLoanReqMf ===>>>", res);
      return res;
    } catch (error: any) {
      console.error("API_postOnGroundLoanReqMf ====>>>", error);
      return error.response;
    }
  };
};

export const API_postOnGroundLoanReqSme = (data: LoanReqData) => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
          "Content-Type": "multipart/form-data",
        },
      };
      let form_data = new FormData();
      form_data.append("full_name", data.full_name);
      form_data.append("phone_number", data.phone_number);
      form_data.append("amount_of_loan", data.amount_of_loan as any);
      form_data.append("product_id", data.amount_of_loan as any);

      form_data.append("branch", String(data.branch));
      form_data.append("government", String(data.government));
      //form_data.append("typeOfProduct", String(data.productType));
      //form_data.append("productSelected", String(data.productSelected));
      form_data.append("category", data.category);
      form_data.append(
        "have_official_document",
        String(data.have_official_document)
      );
      form_data.append(
        "sales_over_one_million",
        String(data.sales_over_one_million)
      );

      const res = await axios.post(
        endPoints.application.onGroundLoanReq,
        form_data,
        config
      );
      // console.log("API_postOnGroundLoanReqSme ===>>>", res.data);
      return res;
    } catch (error: any) {
      // console.error("API_postOnGroundLoanReqSme ====>>>", error);
      return error.response;
    }
  };
};


export const API_postOnGroundLoanReqVse = (data: VseLoanReqData) => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
          "Content-Type": "multipart/form-data",
        },
      };
      let form_data = new FormData();
      form_data.append("full_name", data.full_name);
      form_data.append("phone_number", data.phone_number);
      //form_data.append("amount_of_loan", data.amount_of_loan as any);
      //form_data.append("product_id", data.amount_of_loan as any);

      form_data.append("branch", String(data.branch));
      form_data.append("government", String(data.government));
      form_data.append("product_type_value", String(data.product_type));
      form_data.append("product", String(data.product));
      //form_data.append("category", data.category);
      form_data.append( "have_official_document", String(data.have_official_document));
      //form_data.append("sales_over_one_million", String(data.sales_over_one_million));

      const res = await axios.post( endPoints.application.onVseLoanReq, form_data, config);
      // console.log("API_postOnGroundLoanReqSme ===>>>", res.data);
      return res;
    } catch (error: any) {
      // console.error("API_postOnGroundLoanReqSme ====>>>", error);
      return error.response;
    }
  };
};

export const API_postOnGroundLoanReqLv = (data: LoanReqData) => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
          "Content-Type": "multipart/form-data",
        },
      };
      let form_data = new FormData();
      form_data.append("full_name", data.full_name);
      form_data.append("phone_number", data.phone_number);
      form_data.append("amount_of_loan", data.amount_of_loan as any);
      form_data.append("product_id", data.amount_of_loan as any);
      form_data.append(
        "transportation_product_type_id",
        data.transport_poduct_type_id as any
      );
      form_data.append("branch", String(data.branch));
      form_data.append("government", String(data.government));
      form_data.append("category", data.category);

      const res = await axios.post(
        endPoints.application.onGroundLoanReq,
        form_data,
        config
      );
      console.log("API_postOnGroundLoanReqLv ===>>>", res);
      return res;
    } catch (error: any) {
      console.error("API_postOnGroundLoanReqLv ====>>>", error);
      return error.response;
    }
  };
};

export const API_postOnlineLoanReq = (data: LoanReqOnlineData) => {
  return async (_: React.Dispatch<any>, getState: () => RootState) => {
    const config = {
      headers: {
        "Accept-Language": getState().settingsReducer.lang,
      },
    };
    try {
      let loan_form_data = new FormData();
      loan_form_data.append("full_name", data.full_name);
      loan_form_data.append("phone_number", data.phone_number);
      loan_form_data.append("address", data.address);
      loan_form_data.append("purpose_of_loan", data.purpose_of_loan);
      loan_form_data.append("government", String(data.government));
      loan_form_data.append("branch", String(data.branch));
      const res = await axios.post(
        endPoints.application.onlineLoanReq,
        loan_form_data,
        config
      );
      console.log("API_postOnlineLoanReq ====>>>", res);
      return res;
    } catch (error: any) {
      console.error("API_postOnlineLoanReq ====>>>", error);
      return error.response;
    }
  };
};

export const API_getAmountOfLoansMf = () => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
        },
      };
      const res = await axios.get(endPoints.content.amountOfLoansMf, config);
      dispatch(setAmountOfLoansMf(res.data));
      console.log("API_getAmountOfLoansMf ===>>>", res.data);
      // return res
    } catch (error) {
      console.error("API_getAmountOfLoansMf ====>>>", error);
    }
  };
};

export const API_getAmountOfLoansSme = () => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
        },
      };
      const res = await axios.get(endPoints.content.amountOfLoansSme, config);
      dispatch(setAmountOfLoansSme(res.data));
      console.log("API_getAmountOfLoansSme ===>>>", res.data);
      // return res
    } catch (error) {
      console.error("API_getAmountOfLoansSme ====>>>", error);
    }
  };
};
export const API_getAmountOfLoansLv = () => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
        },
      };
      const res = await axios.get(endPoints.content.amountOfLoansLv, config);
      dispatch(setAmountOfLoansLv(res.data));
      console.log("API_getAmountOfLoansLv ===>>>", res.data);
      // return res
    } catch (error) {
      console.error("API_getAmountOfLoansLv ====>>>", error);
    }
  };
};

export const API_getPhoneNumerLoanStatus = (
  phoneNumber: string,
  loanType: GeneralLoanType
) => {
  return async (_: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
        },
      };
      const res = (await axios.get(
        endPoints.application.checkPhoneNumberGeneral +
        phoneNumber +
        `?type=${loanType}`,
        config
      )) as unknown as AxiosResponse<any[]>;
      // console.log("API_postPhoneNumerToCheck ===>>>", res);
      return res.data.length == 0 ? true : false;
    } catch (error) {
      // console.error("API_postPhoneNumerToCheck ====>>>", error);
    }
  };
};

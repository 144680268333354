export const ar = {
  translation: {
    "Aman أمان للتمويل متناهي الصغر": "أمان للتمويل متناهي الصغر",
    "Aman clients": "عملاء أمان",
    "Financing Terms": "شروط التمويل",
    "Financing term and payment": "مزايا وتكاليف التمويل",
    "Advantages and costs of financing": "مدة وسداد التمويل",
    Administration: "الإدارة",
    "societal role": "الدور المجتمعي",
    "Aman offices": "مكاتب أمان",
    "Aman Finance": "تمويل أمان",
    "questions and answers": "أسئلة وأجوبة",
    "Continue": 'استمرار',
    "A set of frequently asked questions about Aman financing, terms and conditions, and how to pay":
      "مجموعة من الأسئلة الشائعة عن تمويل أمان وشروطه وكيفية السداد",
    About: "عن أمان",
    Services: "الخدمات",
    "Financing Programs": "برامج التمويل",
    "Contact us": "تواصل معنا",
    "annual report": "التقرير السنوي",
    "online merchants": "تجار الأونلاين",
    "Small and medium finance": "تمويل المشروعات المتوسطة والصغيرة",
    "micro finance": " تمويل المشروعات المتناهية الصغر",
    news: "الأخبار",
    "Aman App": "تطبيق أمان",
    "Privacy Policy": "سياسة الإستخدام والخصوصية",
    "Guide to protect customers in the non-banking sector": "دليل حماية المتعاملين فى القطاع غير المصرفى",
    jobs: "الوظائف",
    "Financial Supervisory Authority License No. 4": "ترخيص الهيئة العامة للرقابة المالية رقم 4",
    "Request for Funding": "أطلب تمويلك الأن",
    "Pay your installments": "سدد أقساطك أونلاين",
    lang: "EN",
    "The latest company to provide microfinance services in Egypt with the highest technological systems and programs":
      "أحدث شركة لتقديم خدمات التمويل المتناهي الصغر في مصر و بأعلى النظم والبرامج التكنولوجية",
    "Aman Microfinance Company has innovative solutions, always focusing on the client and understanding his needs, and is fully aware of the needs of the small and micro enterprises sector.":
      "تمتلك شركة أمان لتمويل المشروعات المتناهية الصغر حلول مبتكرة، تركز دائما على العميل وتفهم إحتياجاته، وهي على وعي كامل باحتياجات قطاع المشروعات الصغيرة ومتناهية الصغر",
    Vision: "الرؤية",
    "About aman": "نبذة عن أمان",
    "Active clients": "عميل نشط",
    "Branches all over Egypt": "فرع فى أنحاء مصر ",
    "We aim to be the leader in microfinance": "نهدف إلى نكون الرائد في مجال التمويل متناهي الصغر",
    "Aman Microfinance Company relies on technical and technological expertise, credibility, trustworthiness and market leadership of the parent company Raya.":
      "تعتمد شركة أمان لتمويل المشروعات المتناهية الصغر على الخبرة الفنية والتكنولوجية والمصداقية والجدارة بالثقة وقيادة السوق لدى الشركة الأم راية",
    "As well as on the expertise of the Aman Financial Services Company, which is owned by the “Aman” company for electronic payment services, which has tremendous experience in the Egyptian market, especially in the field of providing electronic payment solutions. She also established a company (Fawry) for electronic payment services":
      "كذلك على الخبرة الخاصة بشركة أمان للخدمات المالية والتي تمتلكها شركة «أمان» لخدمات الدفع الإلكتروني التي لديها خبرات هائلة بالسوق المصري وخصوصا في مجال توفير حلول الدفع الإلكتروني. كما أسست شركة (فوري) لخدمات الدفع الإلكتروني",
    "More about Raya Company": "المزيد عن شركة راية",
    "Enhancing and spreading financial inclusion and leadership in providing financing to business owners and micro-enterprises with appropriate speed and flexibility, and using the best smart technological means":
      "تعزيز ونشر الشمول المالى والريادة فى توفير التمويل ﻷصحاب الأعمال والمشروعات متناهيه الصغر بالسرعه والمرونة الملائمين، وبإستخدام أفضل الوسائل التكنولوجية الذكية",
    "To be (Aman) the market leader in using the best programs and smart technological solutions to provide all non-banking financial services and products to the broad sector of craftsmen and micro-business owners, with speed and flexibility appropriate to the nature of dealing with clients of this sector, and through branches spread in all governorates of the Republic .":
      "أن تكون شركه (أمان) الشركة الرائدة فى السوق فى استخدام أفضل البرامج والحلول التكنولوجية الذكية لتقديم كافة الخدمات والمنتجات المالية غير المصرفية للقطاع العريض من ارباب الحرف والأعمال متناهية الصغر، بالسرعة والمرونة الملائمين لطبيعة التعامل مع عملاء هذا القطاع، ومن خلال فروع منتشرة بجميع محافظات الجمهوريه.",
    "Aman Microfinance Company aims to provide financing to business owners and small and micro enterprises on easy terms and procedures commensurate with the nature of the activities practiced by these groups within the different regions and governorates in which they practice their activities and in accordance with the Small and Micro Enterprises Regulations Law and the rules and standards of practice issued by the Authority General Financial Supervisory Authority to regulate the activity.":
      "تهدف شركة أمان لتمويل المشروعات المتناهية الصغر إلى تقديم التمويل لأصحاب الأعمال والمشروعات الصغيرة ومتناهية الصغر بشروط وإجراءات ميسرة تتناسب مع طبيعة الأنشطة التى تمارسها تلك الفئات داخل المناطق والمحافظات المختلفة التى يمارسون فيها أنشطتهم وبما يتوافق مع قانون تنظيم المشروعات الصغيرة ومتناهية الصغر وقواعد ومعايير الممارسة الصادرة عن الهيئة العامة للرقابة المالية لتنظيم النشاط.",
    "The message": "الرسالة",
    Objectives: "الأهداف",
    "About Aman": "عن أمان",
    "Management Team": "فريق الإدارة",
    "Please leave your data and we will reply as soon as possible": "يرجى ترك البيانات الخاصة بك و سوف نقوم بالرد في أقرب وقت ممكن",
    "Aman appreciates your valuable feedback, suggestions, or any inquiries": "أمان تقدر ملاحظاتك القيمة، اقتراحاتك، او أي استفسار",
    "100 New Branches": "100 فرع جديد",
    "Financial Inclusion": "الشمول المالي",
    "Aman Microfinance Company was established in 2018 as one of the arms of Aman Financial Services Company to provide easier financial services for economically active groups that are not available to them with financial services appropriate to the nature of these groups":
      "تأسست شركة «أمان للتمويل المتناهي الصغر» في عام 2018 كأحد الأذرع لشركة أمان للخدمات المالية لتوفر خدمات مالية اسهل للفئات الناشطة إقتصادياً والغير متاح لها الخدمات المالية المناسبة لطبيعة تلك الفئات",
    Foundation: "التأسيس",
    Jobs: "الوظائف",
    "Aman Microfinance is the latest Raya companies, providing financial services to business owners and small and micro enterprises in all governorates":
      "«أمان للتمويل متناهي الصغر» هي أحدث شركات راية ،توفر الخدمات المالية لأصحاب الأعمال والمشروعات الصغيرة ومتناهية الصغر بجميع المحافظات",
    "Aman Microfinance Company provides easy financing methods, based on innovation, continuous development, an understanding of market needs, and speed in providing the financing needs of its clients in a timely manner.":
      "توفر شركة «أمان لتمويل المشروعات المتناهية الصغر» طرق تمويل تتسم بالسهولة ، قائمة على الابتكار والتطوير المستمر وفهم لإحتياجات السوق، والسرعة فى توفير احتياجات عملائها التمويلية في الوقت المناسب",
    "More of our services": "المزيد من خدماتنا",
    "In your hands now": "بين يديك الأن",
    "Applying for financing is now easier through": "طلب التمويل أصبح الأسهل الأن من خلال",
    "Aman Microfinance Application": "تطبيق أمان للتمويل متناهي الصغر",
    "Submit and track your financing request": "قدم وتابع طلب تمويلك",
    "Follow the installment dates": "تابع مواعيد الأقساط",
    "Contact customer service": "تواصل مع خدمة العملاء",
    "Browse Aman Store and order any product that serves your project": "تصفح متجر أمان وأطلب شراء أي منتج يخدم مشروعك",
    "Aman success stories": "قصص نجاح أمان",
    "Online Merchant Finance": "تمويل تجار الأونلاين",
    "Online merchant financing product designed to support your e-commerce with minimal conditions and procedures":
      "منتج تمويل تجار الأونلاين صمم من أجل دعم تجارتك الإكترونية بأقل الشروط والإجراءات",
    "Maximum Finance Amount": "أقصى مبلغ للتمويل",
    "Financing Term": "مدة التمويل",
    "1 month to 2 years": "شهر إلى سنتين",
    "200,000 EGP": "٢٠٠,٠٠٠ جنيه",
    "Do you own an online store on Facebook - Instagram - Souq - Jumia - Noon and are you looking for financing to expand your business?":
      "هل تملك متجر إلكتروني على فيسبوك - انستجرام - سوق - جوميا - نون وتبحث عن تمويل لتوسيع تجارتك؟",
    "Get started today with online merchant financing provided by Aman": "إبدأ اليوم مع تمويل تجار الأونلاين المقدم من أمان",
    "Conditions to be met by the client": "الشروط الواجب توافرها في العميل",
    "To be of Egyptian nationality and residing within the governorate in which the client, the applicant for financing, resides and works in.":
      "أن يكون مصري الجنسية و مقيم داخل نطاق المحافظة التي يقيم فيها العميل طالب التمويل و يعمل فيها.",
    "Age ranges from 21 to 63 years when funding is granted.": "يتراوح السن من 21 سنة إلى 63 سنة عند منح التمويل.",
    "To have a good reputation through the credit and field inquiries prepared for them for each of the place of residence and the place of work or the place of practice of the activity.":
      "أن يتمتعو بسمعة جيدة من خلال الإستعلام الإئتماني و الميداني المعد عنهم لكل من محل السكن و جهة العمل أو مقر مزاولة النشاط.",
    "To have a valid national ID card.": "أن يكون لديه بطاقة رقم قومي سارية.",
    "Not to be a current client or guarantor of the company.": "ألا يكون عميل أو ضامن حالي في الشركة.",
    "That he has been practicing the activity for a year.": "أن يكون مر علي مزاوله النشاط سنه.",
    "Start a funding request": " إبدأ طلب التمويل",
    "Online merchant financing request": "طلب تمويل تجار الأونلاين",
    "Full Name": "اﻻﺳﻢ اﻟﺮﺑﺎﻋﻲ",
    "Mobile Phone": "ﺗﻠﻴﻔﻮﻥ اﻟﻤﺤﻤﻮﻝ",
    "Activity Information": "معلومات عن النشاط",
    "Do you have an existing business that has been in existence for at least a year?": "هل لديك نشاط قائم ومر على تأسيسه على الأقل سنة؟",
    "The title of the existing activity": "عنوان النشاط القائم",
    "The branch closest to the workplace": "الفرع الاقرب الى محل العمل",
    "Purpose of Funding": "اﻟﻐﺮﺽ ﻣﻦ اﻟﺘﻤﻮﻳﻞ",
    "The activity must be over a year old": "يجب أن يكون مر علي مزاوله النشاط سنه",
    "Send Request": " إرسال الطلب",
    "Choose a Governorate..": "ﺃﺧﺘﺮ ﻣﺤﺎﻓﻈﺔ ..",
    "Choose Branch": "اختر الفرع",
    Yes: "نعم",
    No: "لا",
    "Amount of loan": "ﻣﺒﻠﻎ اﻟﺘﻤﻮﻳﻞ",
    "Governorate in which the activity is located": "اﻟﻤﺤﺎﻓﻈﺔ اﻟﺘﻲ يوجد بها النشاط",
    "Full name": "اﻻﺳﻢ اﻟﺮﺑﺎﻋﻲ",
    "Nearest branch To the workplace": "اﻟﻔﺮﻉ ( الأقرب إلى محل العمل )",
    "Phone number": "ﺗﻠﻴﻔﻮﻥ اﻟﻤﺤﻤﻮﻝ",
    "Existing income-generating projects are financed and have been established for at least one year":
      "يتم تمويل المشاريع القائمة المدرة للدخل ومر على تأسيسها عام على الأقل",
    "Loan request application form": "ﺇﺳﺘﻤﺎﺭﺓ ﻃﻠﺐ ﺗﻤﻮﻳﻞ",
    "Apply request": "ﻗﺪﻡ اﻟﻄﻠﺐ",
    Sending: "جارى الإرسال",
    "phone number should only be digit and 11 digit": "يجب أن يتكون رقم الهاتف من 11 رقمًا فقط",
    "The financing is on us and the project is on you": "التمويل علينا والمشروع عليك",
    "Quick procedures for disbursing funding through the use of modern technology in disbursement":
      "إجراءات سريعة لصرف التمويل من خلال إستخدام التكنولوجيا الحديثة في الصرف",
    "All rights reserved © 2021": "جميع الحقوق محفوظة ©2021",
    Categories: "التصنيفات",
    "About Raya": "عن راية",
    "Our News": "أخبارنا",
    "Send a message": "أرسل رسالة",
    "Contact Us": "تواصل عنا",
    "Join Us": "إنضم إلينا",
    "Finance Request": "طلب تمويل",
    "Browse Aman Store": "تصفح متجر أمان",
    "Aman Store": "متجر أمان",
    "More About Us": "المزيد عنّا",
    "Aman Branches": "فروع أمان",
    "Our Services": "خدماتنا",
    "Design and Implementation": "تصميم وتنفيذ",
    "Aman microfinance": "أمان للتمويل المتناهى الصغر",
    "Do you have official documents for the activity? (commercial register/tax card)?": "هل تمتلك مستندات رسمية للنشاط؟ (سجل تجارى / بطاقة ضريبية)",
    "Are the company’s sales of one million pounds or more per year?": "هل مبيعات الشركة مليون جنية او اكثر فى السنة؟",
    "If the company’s sales are less than one million pounds, you can apply for microfinance":
      "اذا كانت مبيعات الشركة أقل من مليون جنية يمكنك التقديم على التمويل متناهى الصغر",
    "Send OTP": "أرسل كود التحقق",
    Verify: "تحقق",
    "Who are aman clients?": "من هم عملاء أمان؟",
    "They are all clients of existing and income-generating businesses and small and micro-enterprises who enjoy a good reputation.":
      "هم كافة العملاء من أصحاب الأعمال والمشروعات الصغيرة ومتناهية الصغر القائمة والمدرة للدخل والذين يتمتعون بالسمعة الجيدة.",
    "What kind of activities does AMAN fund?": "ما هي نوع الأنشطة التي تمولها أمان؟",
    "Commercial - Industrial - Service - Agricultural": "تجاري - صناعي - خدمي - زراعي",
    "What are the general conditions and characteristics that must be met by the client?": "ما الشروط والسمات العامة الواجب توافرها في العميل؟",
    "To be of Egyptian nationality.": "أن يكون مصري الجنسية.",
    "Age ranges from 21 years to 63 years.": "يتراوح السن من 21 سنة حتي 63 سنة.",
    "That the client has an existing income-generating activity in the payment of the finance amount and its charges.":
      "أن يكون لدي العميل نشاط قائم مدر للدخل يفي بسداد مبلغ التمويل وأعبائه.",
    "That the customer enjoys a good reputation through the credit inquiry prepared for him and the field inquiry through his place of residence and the place of activity.":
      "أن يتمتع العميل بسمعة جيدة من خلال الإستعلام الإئتماني المعد عنه والإستعلام الميداني من خلال محل السكن ومكان مزاولتة للنشاط.",
    "Not to be a guarantor for one of the company's existing clients.": "ألا يكون ضامنا لأحد العملاء القائمين بالشركة.",
    "To have a guarantor who enjoys a good reputation from one of his relatives up to the fourth degree. In the absence of a relative, the guarantor is a person with a fixed income, an employee or a pensioner.":
      "أن يكون لديه ضامن يتمتع بسمعة جيدة من أحد أقاربه حتى الدرجه الرابعة وفي حالة عدم توافر أحد الأقارب يكون الضامن أحد الأشخاص ذو الدخل الثابت موظف أو صاحب معاش.",
    "The client must provide proof of his place of business and his permanent residence.":
      "أن يقدم العميل ما يثبت مكان مزاولته للنشاط ومحل سكنه الدائم.",
    "What are the requirements in the financing documents when the client applies for financing?":
      "ما الإشتراطات في مستندات التمويل عند تقدم العميل بطلب التمويل؟",
    "Complete the financing request and sign it from the client and the guarantors before disbursement.":
      "استيفاء طلب التمويل وتوقيعه من العميل والضامنين قبل الصرف.",
    "A copy of the national ID card for both the customer and the guarantors, provided that it is valid at the time of exchange.":
      "صورة من بطاقة الرقم القومي لكلا من العميل والضامنين على أن تكون سارية وقت الصرف.",
    "Proof of the residence of the client and the guarantor and the location of the activity by submitting any governmental document (electricity - gas - telephone - water receipt).":
      "إثبات سكن العميل والضامن ومقر النشاط بتقديم أي مستند حكومي) إيصال كهرباء - غاز - تليفون –مياه).",
    "A license to practice the activity, tax card, commercial register and a recent extract from it (if any) and in cases that require it, the amount of financing is greater than 35,000 pounds.":
      "رخصة مزاولة النشاط والبطاقة الضريبية والسجل التجارى ومستخرج حديث منه) ان وجد (وفى الحالات التى تتطلب ذلك ) مبلغ التمويل أكبر من 35000 جنيه ).",
    "Conducting credit inquiries about customers through the company.": "إجراء الإستعلام الإئتماني عن العملاء عن طريق الشركة.",
    "What are the terms and systems of financing and repayment?": "ما هي مدد ونظم التمويل والسداد؟",
    "Financing terms and payment systems: Financing terms range from 4 months to 24 months (in a gradual manner) and the payment is in equal installments to be paid monthly and start after a month from the date of disbursement of financing.":
      "مدد التمويل ونظم السداد : يتراوح آجال التمويل من 4 أشهر حتي 24 شهر (بشكل متدرج) ويكون السداد على أقساط متساوية تسدد شهريا وتبدأ بعد شهر من تاريخ صرف التمويل.",
    "It is granted gradually according to the volume of activity and ability to pay.": "يتم منحها بشكل متدرج وفقا وحجم النشاط والقدرة على السداد.",
    "What are the financing amounts?": "ما هي مبالغ التمويل؟",
    "Financing starts from a minimum of 4,000 EGP up to a maximum of 200,000 EGP":
      "يبدأ التمويل من الحد الأدني 4,000 وحتى الحد الأقصى 200,000 جنيه مصري",
    "What are the advantages of financing with Aman?": "ما هي مزايا التمويل مع أمان؟",
    "Quick procedures for disbursing funding through the use of modern technology in disbursement.":
      "إجراءات سريعة لصرف التمويل من خلال إستخدام التكنولوجيا الحديثة في الصرف.",
    "Using secure POS machines for electronic payments to pay the financing installment, after being approved by the Financial Supervisory Authority.":
      "إستخدام ماكينات نقاط البيع POS الخاصة بأمان للمدفوعات الإلكترونية لدفع قسط التمويل، بعد إقرارها من قبل الهيئة العامة للرقابة المالية.",
    "Financing is tailored to the size of the client's business.": "التمويل مصمم ليناسب حجم نشاط العميل.",
    "Life insurance within the limits of the financing amount and term.": "تأمين حياة في حدود مبلغ ومدة التمويل.",
    "Benefit from a 5% discount from Aman stores for installments during the financing period, provided that the payment is regular.":
      "الإستفادة من خصم 5 % من محلات أمان للتقسيط خلال مدة التمويل بشرط الانتظام في السداد.",
    "Financing micro-entrepreneurs with easy terms and procedures": "تمويل أصحاب المشروعات متناهية الصغر بشروط وإجراءات ميسرة",
    "In accordance with the provisions of Law 141 of 2014 and the rules and standards issued by the Financial Supervisory Authority to regulate microfinance activity":
      "وفقاً لأحكام القانون 141 لسنة 2014 والقواعد والمعايير الصادرة من الهيئة العامة للرقابة المالية لتنظيم نشاط التمويل متناهي الصغر",
    "Funding value": "قيمة التمويل",
    "Read more about financing terms and requirements": "إقرأ المزيد عن شروط التمويل ومتطلباته",
    "Types of Activities Aman Funds": "أنواع الأنشطة التي تمولها أمان",
    "Financing all existing income-generating activities": "تمويل كافة الأنشطة القائمة المولدة للدخل",
    "Apply for free financing": "طلب الحصول على التمويل مجاناً",
    "Financing is tailored to suit the client's business volume": "التمويل مصمم ليناسب حجم نشاط العميل",
    "In the event of the customer's death during the validity period of the financing, their families, the “legitimate heirs” are exempted from paying the financing due to them, and this financing is paid on their behalf regardless of the remaining amount.": `فى حالة وفاة العميل أثناء مدة سريان التمويل يتم اعفاء ذويهم "الورثة الشرعين" من سداد التمويل المستحق عليهم،ويتم سداد هذا التمويل نيابة عنهم مهما كان المبلغ المتبقى`,
    "Obtaining a 5% discount on the total purchases of Aman for Microfinance from all “Aman Installment Stores” for sale, numbering (260) stores, spread across 18 governorates of the Republic (whether these purchases are in cash or in installments)": `الحصول على خصم 5% على اجمالى مشتريات أمان للتمويل متناهي الصغر من جميع "محلات تقسيط أمان" للبيع عددها (260) محل والمنتشرة على مستوى 18 محافظة من محافظات الجمهورية ( سواء كانت هذة المشتريات نقدا أو بالتقسيط )`,
    Commercial: "تجاري",
    Industrial: "صناعي",
    Service: "خدمي",
    Agricultural: "زراعى",
    "Financing is designed to suit your activity, no matter how small": "التمويل صمم لكي يتناسب مع نشاطك مهما كان صغيراً",
    "The value of the financing ranges from 4,000 EGP up to 200,000 EGP, to be granted gradually":
      "قيمة التمويل تتراوح من 4,000 جنيه وحتي 200,000 جنيه يتم منحها بشكل متدرج",
    "Egyptian Pound": "جنيه مصري",
    "The duration of the financing ranges from 4 months to 24 months, depending on the amount and purpose of the financing and the nature of the target client's activity.":
      "مدة التمويل تتراوح من 4 شهور حتي 24 شهراً حسب مبلغ التمويل والغرض منه وطبيعة نشاط العميل المستهدف.",
    Months: "شهور",
    Month: "شهر",
    "Paying the financing": "سداد التمويل",
    "The payment shall be in equal installments, to be paid monthly, starting one month after the date of disbursement of the financing":
      "يكون السداد على أقساط متساوية تسدد شهريا وتبدأ بعد شهر من تاريخ صرف التمويل",
    "The possibility of payment through a network of Aman branches, electronic payment methods and in the nearest place to you.":
      "إمكانية السداد عن طريق شبكة فروع أمان, ووسائل الدفع الالكترونى وفي أقرب مكان إليك.",
    "The foundations of our interest in our customers": "أسس اهتمامنا بعملائنا",
    "Guidelines for obtaining financing from Aman": "إرشادات الحصول على تمويل من أمان",
    "Simple procedures for financing without relying on any traditional guarantees": "إجراءات بسيطة لصرف التمويل دون الاعتماد على أى ضمانات تقليدية",
    "Free life insurance for customers for the duration of the financing period": "تأمين حياة على العملاء مجاناً طوال مدة سريان التمويل",
    "Apply the principles of customer protection": "تطبيق مبادئ حماية العملاء",
    "Telephone Number": "رقم الهاتف",
    "Vodafone cash wallet phone number": 'رقم هاتف محفظة فودافون كاش',
    "The Address": "العنوان",
    "Message Content": "محتوى الرسالة",
    "Date of Birth": "تاريخ الميلاد",
    City: "المدينة",
    "City*": "المدينة*",
    "To Complain": "للشكوى",
    "For Suggestions": "للمقترحات",
    "Be sure to get the payment card at the time of exchange and a receipt indicating the payment when paying cash inside our branches.":
      "ﺇﺣﺮﺹ ﻋﻠﻰ اﻟﺤﺼﻮﻝ ﻋﻠﻰ ﺑﻄﺎﻗﺔ اﻟﺴﺪاﺩ ﻋﻨﺪ اﻟﺼﺮﻑ ﻭﻋﻠﻰ ﺇﻳﺼﺎﻝ ﺑﻤﺎ ﻳﻔﻴﺪ اﻟﺴﺪاﺩ ﻋﻨﺪ اﻟﺪﻓﻊ اﻟﻨﻘﺪﻱ ﺩاﺧﻞ ﻓﺮﻭﻋﻨﺎ.",
    "Be sure to deal with company employees only.": "اﺣﺮﺹ ﻋﻠﻰ اﻟﺘﻌﺎﻣﻞ ﻣﻦ ﺧﻼﻝ ﻣﻮﻇﻔﻲ اﻟﺸﺮﻛﺔ ﻓﻘﻂ.",
    "Applying for funding is free.": "ﻃﻠﺐ اﻟﺤﺼﻮﻝ ﻋﻠﻲ اﻟﺘﻤﻮﻳﻞ ﻣﺠﺎﻧﻲ.",
    "Terms and Guidelines for Getting Started with Amana": "ﺷﺮﻭﻁ ﻭﺇﺭﺷﺎﺩاﺕ ﻟﺒﺪء اﻟﺘﻤﻮﻳﻞ ﻣﻊ ﺃﻣﺎﻥ",
    "General conditions and features to be available in the client": "اﻟﺸﺮﻭﻁ ﻭاﻟﺴﻤﺎﺕ اﻟﻌﺎﻣﺔ اﻟﻮاﺟﺐ ﺗﻮاﻓﺮﻫﺎ ﻓﻲ اﻟﻌﻤﻴﻞ",
    "Important Alert": "ﺗﻨﺒﻴﻪ ﻫﺎﻡ",
    "The ability to pay through a network of Aman branches, electronic payment methods and at the nearest place to you.":
      "ﺇﻣﻜﺎﻧﻴﺔ اﻟﺴﺪاﺩ ﻋﻦ ﻃﺮﻳﻖ ﺷﺒﻜﺔ ﻓﺮﻭﻉ ﺃﻣﺎﻥ، ﻭﻭﺳﺎﺋﻞ اﻟﺪﻓﻊ اﻹﻟﻜﺘﺮﻭﻧﻰ ﻭﻓﻲ ﺃﻗﺮﺏ ﻣﻜﺎﻥ ﺇﻟﻴﻚ.",
    "Have questions? Read the answers to the most frequently asked questions about Amanah financing":
      "ﻟﺪﻳﻚ ﺃﺳﺌﻠﺔ؟ ﺇﻗﺮﺃ ﺇﺟﺎﺑﺎﺕ ﺃﻛﺜﺮ اﻷﺳﺌﻠﺔ ﺷﻴﻮﻋﺎً ﻋﻦ ﺗﻤﻮﻳﻞ ﺃﻣﺎﻥ",
    "Apply for financing now": "قم بطلب التمويل الآن",
    "To be of Egyptian nationality": "أن يكون مصري الجنسية",
    "The age ranges from 21 to 63 years.": "ﻳﺘﺮاﻭﺡ اﻟﺴﻦ ﻣﻦ 21 ﺳﻨﺔ ﺣﺘﻰ 63 ﺳﻨﺔ.",
    "That the client has an existing income-generating activity that satisfies the payment of the finance amount and its charges.":
      "ﺃﻥ ﻳﻜﻮﻥ ﻟﺪﻱ اﻟﻌﻤﻴﻞ ﻧﺸﺎﻁ ﻗﺎﺋﻢ ﻣﺪﺭ ﻟﻠﺪﺧﻞ ﻳﻔﻲ ﺑﺴﺪاﺩ ﻣﺒﻠﻎ اﻟﺘﻤﻮﻳﻞ ﻭﺃﻋﺒﺎﺋﻪ.",
    "That the customer enjoys a good reputation through the prepared credit inquiry and the field inquiry through the place of residence and the place where the activity is carried out.":
      "ﺃﻥ ﻳﺘﻤﺘﻊ اﻟﻌﻤﻴﻞ ﺑﺴﻤﻌﺔ ﺟﻴﺪﺓ ﻣﻦ ﺧﻼﻝ اﻹﺳﺘﻌﻼﻡ اﻹﺋﺘﻤﺎﻧﻲ اﻟﻤﻌﺪ ﻋﻨﻪ ﻭاﻹﺳﺘﻌﻼﻡ اﻟﻤﻴﺪاﻧﻲ ﻣﻦ ﺧﻼﻝ ﻣﺤﻞ اﻟﺴﻜﻦ ﻭﻣﻜﺎﻥ ﻣﺰاﻭﻟﺘﺔ ﻟﻠﻨﺸﺎﻁ.",
    "To have a valid National Number Card.": "ﺃﻥ ﻳﻜﻮﻥ ﻟﺪﻳﻪ ﺑﻄﺎﻗﺔ ﺭﻗﻢ ﻗﻮﻣﻲ ﺳﺎﺭﻳﺔ.",
    "To have a guarantor who has a good reputation from one of his first-degree relatives (father - mother - wife - son - daughter).In the event that a first-degree relative is not available, the guarantor is a person with a fixed income (employee or pensioner).":
      "اﻥ ﻳﻜﻮﻥ ﻟﺪﻳﻪ ﺿﺎﻣﻦ ﻳﺘﻤﺘﻊ ﺑﺴﻤﻌﺔ ﺟﻴﺪﺓ ﻣﻦ ﺃﺣﺪ ﺃﻗﺎﺭﺑﻪ ﻣﻦ اﻟﺪﺭﺟﺔ اﻷﻭﻟﻲ )اﻷﺏ - اﻷﻡ - اﻟﺰﻭﺟﺔ - اﻹﺑﻦ - اﻹﺑﻨﻪ ) ﻭﻓﻲ ﺣﺎﻟﺔ ﻋﺪﻡ ﺗﻮاﻓﺮ ﺃﺣﺪ اﻷﻗﺎﺭﺏ ﻣﻦ اﻟﺪﺭﺟﺔ اﻷﻭﻟﻲ ﻳﻜﻮﻥ اﻟﻀﺎﻣﻦ ﺃﺣﺪ اﻷﺷﺨﺎﺹ ﺫﻭ اﻟﺪﺧﻞ اﻟﺜﺎﺑﺖ ( ﻣﻮﻇﻒ ﺃﻭ ﺻﺎﺣﺐ ﻣﻌﺎﺵ ).",
    "The client must provide evidence of his place of business and his permanent residence.":
      "ﺃﻥ ﻳﻘﺪﻡ اﻟﻌﻤﻴﻞ ﻣﺎ ﻳﺜﺒﺖ ﻣﻜﺎﻥ ﻣﺰاﻭﻟﺘﻪ ﻟﻠﻨﺸﺎﻁ ﻭﻣﺤﻞ ﺳﻜﻨﻪ اﻟﺪاﺋﻢ.",
    "Show More": "شاهد المزيد",
    "Fund and get done with aman": "مول وإنجز مع أمان",
    "Aman Microfinance Company has innovative solutions, always focusing on the client and understanding his needs, and is fully aware of the needs of the small and micro enterprises sector":
      "تمتلك شركة أمان لتمويل المشروعات المتناهية الصغر حلول مبتكرة، تركز دائما على العميل وتفهم إحتياجاته، وهي على وعي كامل باحتياجات قطاع المشروعات الصغيرة ومتناهية الصغر",
    "Read more about us": "أقرأ المزيد عنّا",
    "Find the nearest branch": "إبحث عن أقرب فرع",
    "120 K": "120 ألف",
    "Contact us wherever you are": "تواصل معنا أينما كنت",
    "Start and grow your business": "ﺇﺑﺪﺃ ﻭﻛﺒّﺮ ﻣﺸﺮﻭﻋﻚ",
    "The amount of your financing will be determined according to your project and your evaluation": "مبلغ تمويلك بيتحدد حسب مشروعك و تقييمك",
    "Starts at 6000 EGP": "يبدأ ب ٦٠٠٠ جنيه",
    "To 3 million EGP": "إلى ٥ مليون جنيه",
    "Aman Finance Advantages": "مميزات التمويل لدى أمان",
    "Pay at Aman branches and at any electronic payment machine": "السداد في فروع أمان وعند أي ماكينة أمان للدفع الإلكتروني",
    "Discount up to 50% with a aman receipt in selected radiology and analysis centers": "خصم يصل إلي 50% بوصل أمان في مراكز إشاعات و تحاليل مختارة",
    "Quick disbursement procedures": "إجراءات سريعة لصرف التمويل",
    "Aman offices covering all governorates of Egypt, a total of ": "مكاتب أمان تغطي جميع محافظات مصر إجمالي ",
    " branches, and we aim to be the closest to everyone": "  فرع ونهدف لنكون الأقرب للجميع ",
    "Browse the map": "إستعرض الخريطة",
    "View all": "عرض الكل",
    "show more": "عرض المزيد",
    "read more": "اقرأ المزيد",
    "Latest News": "آخر الاخبار",
    "Banking news": "خبر مصرفي",
    All: "الكل",
    "Banking News": "الأخبار المصرفية",
    "General News": "أخبار عامة",
    Next: "التالي",
    Previous: "السابق",
    "Apply now": "قدم الآن",
    "Available Jobs": "الوظائف المتاحة",
    Responsibilites: "المهام الأساسية",
    Requirements: "متطلبات العمل",
    "To Join Us": "للإنضمام إلينا",
    "Drag a file or ": "قم بسحب الملف أو",
    "Upload your resume": "ارفع ملف سيرتك الذاتية",
    "*Fullname": "الاسم الرباعي*",
    "*Email": "*البريد الإلكتروني",
    "*Government": "المحافظة*",
    "*choose a government": "اختر المحافظة*",
    "*Branch": "الفرع*",
    "*Phone Number": "رقم الهاتف المحمول*",
    "National Id": "الرقم القومى",
    "Brief about you": "نبذة عن سيرتك الشخصية",
    Send: "ارسال",
    "Please provide a valid government": "ادخل محافظة صحيحة",
    "Back to available jobs": "العودة للوظائف المتاحة",
    "Be one of Aman microfinance team now": "كن من فريق أمان للتمويل متناهي الصغر الآن",
    "Join Aman Team": "إنضم إلى فريق عمل أمان",
    "If you have the talent and want to join Aman microfinance team then send us your resume now":
      "إذا كنت تملك المهارات والرغبة للإنضمام إلى فريق عمل أمان للتمويل المتناهي الصغر فأرسل إلينا سيرتك الذاتية الآن",
    "choose a branch": "*اختر الفرع",
    "Enter Arabic or English letters only": "ادخل حروف عربية او انجليزية فقط",
    "Please provide a valid email": "ادخل بريد الكتروني صحيح",
    "Please provide a valid AMAN branch": "ادخل فرع لأمان صحيح",
    "Choose Branch..": "اختر الفرع..",
    Check: "تحقق",
    "This phone number is already registerd": "رقم الهاتف هذا مسجل بالفعل",
    "File size cannot exceed 50MB": "حجم الملف لا يتعدى 50MB",
    "Upload a pdf file with size less than 50MB": "الملف يجب ان يكون pdf حجم الملف لا يتعدى 50MB",
    "There is no news now": "لا توجد أخبار جديدة حاليا",
    "Thank you for your interest in knowing what's new in our company": "شكرا لاهتمامك بمعرفة ما هو الجديد في شركتنا",
    "Share Article": "شارك المقال",
    "Also read": "اقرأ أيضا",
    "Aman microfinance branches": `فروع أمان للتمويل متناهي الصغر`,
    "Request your funding now": `قدّم طلبك للتمويل الآن`,
    "Please enter valid full name": "الرجاء إدخال الاسم الكامل الصحيح",
    "Please enter valid phone number": "الرجاء إدخال رقم هاتف صحيح",
    "Choose the type*": "اختر النوع*",
    "Your Message Has been sent successfully": "تم ارسال رسالتك بنجاح",
    "An Error Occured Please try again": "حدث خطأ يرجى المحاولة مرة أخرى",
    "Your Loan request Has been sent successfully": "تم إرسال طلب التمويل الخاص بك بنجاح",
    "Email*": "البريد الالكترونى*",
    Email: "البريد الالكترونى",
    "Apply for loan": "قدم على طلبك",
    "Your Application Has been sent successfully": "تم إرسال طلبك بنجاح",
    "Send Code": "إرسل كود التحقق",
    "Phone Number": "رقم التليفون",
    "Installment Payment": "تسديد الأقساط",
    "Use the phone number registered with Aman": "قم بإدخال الرقم القومى الخاص بك",
    "You will receive a confirmation message that must be written": "سيظهر لك مبلغ القسط المستحق",
    "After verification, you will be able to review your latest installment": "قم بإدخال رقم هاتف المحفظة الذكية",
    "Please enter verification code": "الرجاء إدخال كود التحقق",
    "A text message contains verification code was sent": "تم إرسال رسالة نصية تحتوي على رمز التحقق على ",
    "Check verification code": "التحقق من الكود",
    "Didn't recieve the message?": "لم تصلك رسالة؟ ",
    "Send again": "إعادة إرسال",
    "National ID": "الرقم القومي",
    "Client Name": "إسم العميل",
    Amount: "القيمة المستحقة",
    EGP: "جنيه مصري",
    "Pay Now": "إدفع الأن",
    installment: "قسط",
    "There's no installment for this number": "لا توجد أقساط مسجلة لهذا الرقم",
    "Back to Main": "العودة إلى الرئيسية",
    "Installment paid successfully": "تم سداد القسط بنجاح",
    "Reciept ID": "رقم الفاتورة",
    "Transaction ID": "رقم المعاملة",
    ActivityType: "نوع النشاط",
    "wish to finance": "ترغب في تمويل",
    Motorcycle: "موتوسيكل",
    tricycle: "تروسيكل",
    "Light vehicles": "مركبات خفيفة",
    "Do you have official documents for the activity? (commercial register / tax card) ?":
      "هل تمتلك مستندات رسمية للنشاط؟ (سجل تجارى / بطاقة ضريبية) ؟",
    "Is the company's sales one million pounds or more per year?": "هل مبيعات الشركة مليون جنية او اكثر فى السنة؟",
    "If the company's sales are less than one million pounds, you can apply for microfinance":
      "اذا كانت مبيعات الشركة أقل من مليون جنية يمكنك التقديم على التمويل متناهى الصغر",

    "Nano Lending": "تمويل النانو",
    "It is one of the rapid financing systems from Aman Company that is done in an easy and fast way, and inquiries are made and financing is received on the same day.":
      "هو أحد أنظمة التمويل السريع من شركة أمان يتم بطريقة سهلة وسريعة، ويتم الاستعلام واستلام التمويل فى نفس اليوم.",
    "It is granted to individuals with a minimum of": "يمنح للأفراد بحد أدنى",
    "Egyptian pounds and a maximum of": "جنيه مصري وبحد أقصى",
    "Egyptian pounds per person": "جنيه مصري للفرد الواحد",
    "Download the application now and apply for nano financing": "حمل الأبليكيشن الآن و قدم على طلب تمويل النانو",
    "Follow the necessary steps and go to the nearest branch to you from the network of Aman branches spread all over the Republic to receive the financing on the same day.":
      "اتبع الخطوات اللازمة و توجه لأقرب فرع لك من شبكة فروع أمان المنتشرة فى جميع أنحاء الجمهورية لاستلام التمويل فى نفس اليوم.",
    "Call Us": "أو كلمنا على",
    "Funding Period": "مدة التمويل",
    "1 week - 2 weeks - 1 month - 2 months - 3 months": "أسبوع - أسبوعين – شهر – شهرين – 3 شهور",
    "Payment Periodicity": "دورية السداد",
    "On a weekly basis - two weeks - monthly": "بشكل أسبوعى – أسبوعين – شهري",
    "Funding Disbursement Methods": "طرق صرف التمويل",
    "Through Aman branches for financial services - Aman branches for microfinance":
      "من خلال فروع أمان للخدمات المالية – فروع أمان للتمويل متناهى الصغر",
    "Payment Methods": "طرق السداد",
    "Through Aman Financial Services branches - Aman payment machines - Jumia Pay - Aman application - Banque du Caire wallet":
      "من خلال فروع أمان للخدمات المالية – ماكينات أمان للدفع – جوميا باي - تطبيق أمان – محفظة بنك القاهرة",
    "nano lending": "تمويل النانو",
    "In the event that the financing exceeds the amount of 2000 pounds, the financing will be disbursed to the customer using one of the non-cash disbursement methods":
      "في حالة زيادة التمويل عن مبلغ 2000 جنيه يتم صرف التمويل للعميل بإحدى طرق الصرف الغير نقدى",
    "Inspirational stories": "قصص ملهمة",
    "Product Type": "نوع المنتج",
    "Islamic Loan": " تمويل إسلامي",
    "start with": "يبدأ ب",
    LE: "جنيه",
    to: "إلى",
    million: "مليون جنيه",
    "The value of the financing ranges from": "قيمة التمويل تتراوح من",
    "EGP up to 200,000 EGP, to be granted gradually": "جنيه وحتي 200,000 جنيه يتم منحها بشكل متدرج",
    "A recent and valid commercial register.": "سجل تجاري حديث وساري.",
    "The tax card is valid.": "البطاقة الضريبية سارية .",
    "Customer and guarantor card.": "بطاقة العميل و ضامن.",
    "Documents of ownership or lease of the headquarters and documents of branches or warehouses.":
      "مستندات ملكية او ايجار المقر ومستندات الفروع او المخازن .",
    "The company's articles of incorporation, amendment contracts, or company newspaper.": "عقد تأسيس الشركة او عقود التعديل او صحيفة الشركات .",
    "The last 3 budgets.": "اخر 3 ميزانيات .",
    "The credentials of the chartered accountant": "اوراق اعتماد المحاسب القانوني",
    "Bank account statement.": "كشف حساب بنكي .",
    "A statement of the tax and insurance position": "بيان بالموقف الضريبي و التأميني",
    "The latest social security payment receipt.": "اخر ايصال سداد تأمينات اجتماعية.",
    "Activity license.": "رخصة النشاط .",
    "Basic data certificate from the tax authority.": "شهادة بيانات اساسية من مصلحة الضرائب .",
    "Supply orders, valid contracts, or withdrawal statements": "اوامر توريد او تعاقدات سارية او كشوف مسحوبات",
    "bankruptcy and protesto": "افلاس و بروتستو",
    "Recruitment certificate for clients under 30 years old": "شهادة التجنيد للعملاء الاقل من 30 سنة",
    "Combined statement and related parties": "بيان مجمع و الاطراف المرتبطة",
    "SMEs Repayments": "سداد مشروعات صغيرة ومتوسطة ",
    "SMEs Repayment text": "لسداد مشروعات صغيرة ومتوسطة اقرأ المعلومات جيدا ثم قم بملا البيانات المطلوبة حتى تتمكن من اتمام العملية.",
    "Bank info": "بيانات البنك",
    "Bank Name": "اسم البنك",
    "NBE": "البنك الاهلى المصرى (NBE)",
    "Beneficiary Name": "اسم المستفيد",
    "AMAN micro finance": "أمان للتمويل المتناهى الصغر",
    "Beneficiary address": "عنوان المستفيد",
    "Beneficiary address value": "565 ش فلسطين - المعادى الجديدة - القاهرة - مصر",
    "Beneficiary account number": "رقم حساب المستفيد",
    "International Account Number (IBAN)": "رقم الحساب الدولى (IBAN)",
    "Swift code": "رمز سويفت (Swift)",
    "Tax card number": "رقم البطاقة الضريبية",
    "Enter the tax card number": "ادخل رقم البطاقة الضريبية",
    "Confirm": "تأكيد",
    "Personal Info": "بياناتك الشخصية",
    "back": "رجوع",
    Loading: "تحميل",
    "choose product": " المنتج",
    "type of product": "نوع المنتج",
    "Very small finance" : "تمويل المشروعات الصغيرة جدا"

  },
};

import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import About from "./pages/about";
import Branches from "./pages/branches";
import FAQ from "./pages/faq";
import Landing from "./pages/landing";
import SuccessStories from "./pages/successStorieAll";
import LoanRequest from "./pages/loan_request-mf";
import VSE from "./pages/vse";
import Privacy from "./pages/privacy";
import Services from "./pages/services";
import "./style/main.scss";
import ContactUs from "./pages/contact-us";
import Installments from "./pages/installments";
import LoanRequestOnline from "./pages/loan-online";
import { useDispatch, useSelector } from "react-redux";
import {
  checkAndSetPrevLang,
  initWidthListener,
  removeWidthListener,
  setLang,
} from "store/actions/settingsActions";
import {
  API_getConstant,
  API_getGovernments,
} from "store/actions/contentActions";
import { RootState } from "store/configStore";
import News from "pages/news";
import Careers from "pages/careers";
import CareersForm from "pages/careers_form";
import axios from "axios";
import { baseUrl } from "netwrok/baseUrl";
import {
  API_getAmountOfLoansLv,
  API_getAmountOfLoansMf,
  API_getAmountOfLoansSme,
  API_postOnGroundLoanReqLv,
  API_postOnGroundLoanReqMf,
  API_postOnGroundLoanReqSme,
  API_postOnGroundLoanReqVse
} from "store/actions/loanReqActions";
import Wrapper from "components/ScrollToTop";
import NewsDetails from "pages/news_details";
import NanoLendingInfo from "pages/nano_lending";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import IslamicLoan from "pages/islamic-loan";
import ThanksMessage from "pages/thanks-message";

import InstallmentPaid from "pages/installments/components/InstallmentPaid";
import SmesRepayment from "pages/smes-repayment";

axios.defaults.baseURL = baseUrl;

function App() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { lang } = useSelector((state: RootState) => state.settingsReducer);
  const loc = useLocation();
  const { amountOfLoansMf, amountOfLoansSme, amountOfLoanLv } = useSelector(
    (state: RootState) => state.contentReducer
  );

  useEffect(() => {
    dispatch(checkAndSetPrevLang());
    dispatch(initWidthListener());
    return () => {
      dispatch(removeWidthListener());
    };
  }, []);
  useEffect(() => {
    dispatch(API_getGovernments());
    dispatch(API_getConstant());
  }, [lang]);

  useEffect(() => {
    ReactGA.initialize("G-JM4B88GQJ2");
    ReactGA.send({ hitType: "pageview", page: loc.pathname });
  }, [loc]);
  return (
    <Wrapper>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/branches" element={<Branches />} />
        <Route path="/success-stories" element={<SuccessStories />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/islamic-loan" element={<IslamicLoan />} />
        <Route path="/smes-repayment" element={<SmesRepayment />} />
        <Route path="/installments" element={<Installments />} />
        <Route path="/installments/paid" element={<InstallmentPaid />} />
        <Route path="/installments/:step" element={<Installments />} />
        <Route path="/services" element={<Services />} />
        <Route path="/nano-lending" element={<NanoLendingInfo />} />
        <Route path="/loan/thanks" element={<ThanksMessage />} />

        <Route
          path="/loan-request-mf"
          element={
            <LoanRequest
              type="mf"
              amountOfLoans={amountOfLoansMf}
              API_getAmountsActionThunk={API_getAmountOfLoansMf}
              API_postFormActionThunk={API_postOnGroundLoanReqMf}
            />
          }
        />
        <Route
          path="/loan-request-sme"
          element={
            <LoanRequest
              type="sme"
              amountOfLoans={amountOfLoansSme}
              API_getAmountsActionThunk={API_getAmountOfLoansSme}
              API_postFormActionThunk={API_postOnGroundLoanReqSme}
            />
          }
        />

        <Route
          path="/vse"
          element={
            <VSE
              type="sme"
              amountOfLoans={amountOfLoansSme}
              API_getAmountsActionThunk={API_getAmountOfLoansSme}
              API_postFormActionThunk={API_postOnGroundLoanReqVse}
            />
          }
        />
        <Route
          path="/light-vehicles"
          element={
            <LoanRequest
              type="lv"
              API_postFormActionThunk={API_postOnGroundLoanReqLv}
              amountOfLoans={amountOfLoanLv}
              API_getAmountsActionThunk={API_getAmountOfLoansLv}
            />
          }
        />
        <Route path="/loan-request-online" element={<LoanRequestOnline />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:newsId" element={<NewsDetails />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/:job" element={<CareersForm />} />
      </Routes>
    </Wrapper>
  );
}

export default App;
